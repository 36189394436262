var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "", hideCloseButton: _vm.hideCloseButton },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("p", { staticClass: "f4 white" }),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "w-100 flex flex-column items-center" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaderText === "Converting to Audio ....",
                  expression: "loaderText === 'Converting to Audio ....'",
                },
              ],
              staticClass: "flex w-100 justify-between items-center",
            },
            [
              _c("div", { staticClass: "text-loader" }),
              _c(
                "i",
                { staticClass: "material-icons light-gray f2 v-mid pa1 ml5" },
                [_vm._v("forward")]
              ),
              _c("div", { staticClass: "audio-loader" }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaderText === "Generating AI images ....",
                  expression: "loaderText === 'Generating AI images ....'",
                },
              ],
              staticClass: "flex w-100 justify-center items-center",
            },
            [_c("div", { staticClass: "img-loader" })]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaderText === "Creating visuals ...",
                  expression: "loaderText === 'Creating visuals ...'",
                },
              ],
              staticClass: "flex w-100 justify-center items-center",
            },
            [_c("div", { staticClass: "tag-loader" })]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaderText === "Creating Video ...",
                  expression: "loaderText === 'Creating Video ...'",
                },
              ],
              staticClass: "flex w-100 justify-center items-center",
            },
            [
              _c("div", { staticClass: "video-loader" }, [
                _c("div", { staticClass: "loader__container" }, [
                  _c("div", { staticClass: "loader__film" }, [
                    _c("img", {
                      staticClass: "loader__film-img",
                      attrs: {
                        src: "https://www.dropbox.com/s/o4p5i3nfw92rhfz/film.png?raw=1",
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "loader__film-img",
                      attrs: {
                        src: "https://www.dropbox.com/s/o4p5i3nfw92rhfz/film.png?raw=1",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("img", {
                    staticClass: "loader__camera",
                    attrs: {
                      src: "https://www.dropbox.com/s/348z6yvtt9hbos2/camera.png?raw=1",
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaderText === "Publishing to YouTube ...",
                  expression: "loaderText === 'Publishing to YouTube ...'",
                },
              ],
              staticClass: "flex w-100 justify-center items-center",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/youtube/youtube-logo.svg"),
                  width: "200",
                },
              }),
            ]
          ),
          _vm.loaderText === "Published"
            ? _c(
                "div",
                {
                  staticClass:
                    "flex w-100 flex-column justify-center items-center",
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/youtube/youtube-logo.svg"),
                      width: "200",
                    },
                  }),
                  _c("div", { staticClass: "white mb4" }, [
                    _vm._v("Congratulations!!! 🎉 Video Successfully uploaded"),
                  ]),
                  _c("TheConfetti"),
                  _c("BaseButtonRed", {
                    staticClass: "youtube-link ml1",
                    attrs: {
                      text: "Your video link",
                      icon: "open_in_new",
                      onClick: _vm.youtubeLink,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.loaderText !== "Published"
            ? _c(
                "span",
                {
                  staticClass: "text-display mt4",
                  attrs: { "data-content": _vm.loaderText },
                },
                [_vm._v(_vm._s(_vm.loaderText))]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }